import { render, staticRenderFns } from "./NonConformanceList.vue?vue&type=template&id=323e8a86&scoped=true&"
import script from "./NonConformanceList.vue?vue&type=script&lang=js&"
export * from "./NonConformanceList.vue?vue&type=script&lang=js&"
import style0 from "./NonConformanceList.vue?vue&type=style&index=0&id=323e8a86&prod&lang=scss&scoped=true&"
import style1 from "./NonConformanceList.vue?vue&type=style&index=1&id=323e8a86&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "323e8a86",
  null
  
)

export default component.exports